function readCheckProducts(app, userTags){

// Read all Products on App
app.product().read().then(function(products){

  // Populate Product Overlays
  $.each(products, function(index, product) {

      // Get image and add to UI
      $("div[data-product='" + product.id +"']").html('<img src="'+ product.photos[0] + '" />');

      populateProductInfo(product);

      // Check users Tags to see if they have any Products
      $.each(userTags, function(index, tag) {
          if(tag == product.id) {
            $("div[data-product='" + product.id +"']").addClass('overlay-toggle owned');
          }
      });

  });
  // End reading of Product data

});

// End Read Products
}
