function firstNameProcessing(user) {

var firstName = user.firstName;

// Check if users firstName has been added
if(firstName) {

  // If the username is still the generated one
  if(firstName.match("^anon-")) {

  } else {

    // Otherwise populate input with users name
    $('#user__name').val(user.firstName);

  }

} else {

  // Fallback incase no firstName found
  toaster('Please enter your name...');

}
// End Check firstName

// Handle updating of users firstName and save to account
$('#updateFirstName').submit(function(){

  var firstNameNew = $('#user__name').val();

  if(firstNameNew == ''){

    toaster('Please enter your name...', 'error');

  } else {

    var update = {
      firstName: firstNameNew
    }

    user.update(update).then(function(){
      toaster('Thanks for entering!');
      lockAfterComplete();
    });

  }

  return false;

});
// End updating of firstName

}
