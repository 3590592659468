// Global set-up: get the THNG ID from the URL so we can read the THNG via the API
// ***************

var evtAppId = '';
var appCustomFields = {};
var evtCounterfeitMessages = {};
var productCustomFields = {};

var thngId = getParameterByName('thng'),
    apiKey = getParameterByName('app');

if(!apiKey){

  // toaster('Please scan a QR code to play the game');
  // Don't allow acces to SCNTHNG (as we don't have an appKey)
  // $('.scanBttn').addClass('completed');
  window.location.replace("https://goo.gl/Koyoh8");
  // Analytics at: https://goo.gl/info/Koyoh8
  // Currenty: https://adla17.evrythng.com/?app=[appkey]

} else {

  // Set global app variable using Application API Key
  // ***************
  var app = new EVT.App(apiKey);

  // Once App variable set then process THNG and App config
  // ***************
  app.$init.then(app => {

    // Get the title from the App Name
    var appTitle = app.name;
    document.title = appTitle;

    // Populate App Information modal
    $("#app-information .modal__title").html('App Information');
    $("#app-information .modal__wrapper p").html(app.description);

    // Create var for app ID to create session objects
    var appId = app.id;

    // Check / Create User
    getUser(appId).then(function(user) {

      // Define some variables for checking if the users name is present and the tags (products) scanned
      var firstName = user.firstName;
      var userTags = user.tags;

      // Write the user ID into the UI (only shown once all items collected)
      $('.user__id').html(user.id);

      // Handle checking if the user has entered their name after completing competition and handle processing and saving of name to anon user
      firstNameProcessing(user);

      // Check products and see if user has matching tags for products
      readCheckProducts(app, userTags);

      // If THNG in URL, then process
      if(thngId) {

        // If there's a THNG in the url process it
        readThng(user);

      // If no THNG in URL
      } else {

        // If not THNG in url, check the items the user has and respond accordingly
        var scannedProducts = scannedProducts = user.tags || [];
        var numberItems = scannedProducts.length;

        // Check if they have completed the wardrobe
        if(numberItems > 4) {

          winnerUiSwitch();
          var winningMessage = "All items have been collected! Enter your name to win a prize.";

          if(firstName.match("^anon-")) {
            toaster(winningMessage);
          } else {
            lockAfterComplete();
          }

        // If not - just remind them they have to scan a QR code to play
        } else {

          toaster('Please scan a QR code to play the game');

        }

      }

    });
    // End getUser;

  });

}
