function toaster(message, type) {

    $('.toaster').removeClass("error warning");

    if (type) {

      $('.toaster').addClass(type);

    }

    $('.toaster__message').html(message);
    $('.toaster').addClass('on');

    $('.toaster').click(function(){
      $(this).removeClass('on');
    });

}
